.selected-features-panel {
  position: relative;
}

.panel-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}

.panel-container.active {
  opacity: 1;
  pointer-events: auto;
}

.risk-reduction-pane1 {
  display: grid;
  grid-template-columns: 120px auto;
}

.risk-reduction-donut-chart-container {
  display: flex;
  justify-content: center;
}

.risk-reduction-donut-chart-container > svg {
  display: inline-block;
  margin: auto;
}

.people-buildings-protected-container {
  display: grid;
  grid-template-rows: 50% 50%;
}

.people-protected-pane {
  display: grid;
  grid-template-rows: auto 30px 50px;
}

.people-protected-text-container {
  text-align: left;
  margin-left: 8px;
  margin-bottom: 5px;
}

.people-protected-text {
  font-size: 1.4em;
}

.people-per-icon-text-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.woman-icon {
  width: 10px;
  margin-left: 10px;
  margin-top: -30px;
}

.aeb-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.aeb-container:not(:first-of-type) {
  background-color: white;
}

.aeb-container-title {
  display: flex;
  width: 100%;
  height: 40px;
  background-color: var(--shoreline);
  transition: background-color 0.2s ease-in-out;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  z-index: 1;
}

.aeb-container-title.clickable > * {
  cursor: pointer;
}

.aeb-container-title.selected {
  background-color: var(--red-tide) !important;
}

.aeb-content-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  padding-top: 16px;
  padding-bottom: 20px;
  background-color: white;
  z-index: 1;
}

.aeb-content-container.start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* height: 60%; */
}

.aeb-number-text {
  font-size: 2em;
  text-align: right;
  /* justify-content: center; */
}

.aeb-title-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.aeb-title-text {
  font-size: 1.3em;
  color: white;
  text-align: left;
}

.aeb-container-metric-description {
  margin-bottom: 8px;
}

.no-selected-features-panel {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  /* justify-content: space-around; */
  background-image: linear-gradient(
    rgba(60, 132, 150, 0.6),
    rgba(60, 132, 150, 0.9)
  );
}

.no-selected-features-panel > * {
  padding: 20px 0px;
}

.no-selected-features-panel-text {
  color: white;
  font-size: 2.4em;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.95);
}

.no-selected-features-panel-text > .sub-text {
  color: white;
  font-size: 0.7em;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.95);
  padding: 5px 15px;
}

.fly-to-icon {
  display: flex;
  height: 15%;
  flex-direction: column;
  align-items: flex-end;
  margin: 0px 30px;
}

.fly-to-icon:hover > .fly-to-icon-text {
  border: 5px solid white;
}

.fly-to-icon > * {
  cursor: pointer;
}

.fly-to-icon:hover > img {
  filter: grayscale(0%);
}

.fly-to-icon > img {
  max-height: 100%;
  max-width: 100%;
  position: relative;
  z-index: 2;
}

.fly-to-icon > .fly-to-icon-text {
  color: white;
  text-shadow: 0px 0px 10px black;
  font-size: 1.6em;
  border-radius: 30px;
  padding: 5px 60px 5px 15px;
  position: relative;
  left: -70px;
  top: -30px;
}

.fly-to-icon.flooding > .fly-to-icon-text {
  background-color: #bcdfbb;
}

.fly-to-icon.hex > .fly-to-icon-text {
  background-color: #82cec6;
}

.fly-to-icon.aeb > .fly-to-icon-text {
  background-color: #54aace;
}

.fly-to-icon.aeb-per-ha > .fly-to-icon-text {
  background-color: #2076b3;
}

.layer-icon {
  /* width:  ; */
  position: relative;
  top: -18px;
  left: -12px;
  height: 70px;
  width: auto;
}

.layer-image-container {
  display: flex;
  align-items: center;
  padding: 0 8px;
}

@media (max-width: 768px) {
  .layer-image-container svg {
    transform: scale(1.4);
  }
}

.crl-logo-panel-fill {
  width: 90%;
  padding: 5%;
  opacity: 0.5;
}

.bottom-citation {
  font-size: 1.2em;
}
