.floodgroup-manager-outer-container {
  font-size: 0.9em;
  z-index: 2;
  color: white;
}

.floodgroup-manager-inner-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  border-radius: 100px;
  border-radius: 0px 50px 50px 0px;
  font-size: 1.2em;
  color: black;
  text-align: left;
  padding: 10px 20px 10px 15px;
  margin: 0px;
  @apply bg-shoreline border-open border-4;
}

.left > * > .floodgroup-manager-inner-container {
  border-radius: 50px 0px 0px 50px;
  padding: 10px 15px 10px 20px;
}

.floodgroup-manager-inner-container > * {
  margin: 5px;
}

.circle-selector-container.flooding {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 0px;
}

.circle-selector-text.flooding {
  text-align: center;
  padding: 5px;
  font-size: 0.8em;
  white-space: nowrap;
}

.circle-selector.flooding {
  border-radius: 100px;
  width: 20px;
  height: 20px;
  @apply bg-shoreline-dark;
}

.circle-selector.flooding.selected {
  @apply bg-open;
}

.basemap-options-header {
  padding-bottom: 10px;
  text-align: center;
}

.basemap-options-button {
  display: block;
}

.vl {
  border-left: 1px solid white;
  top: 5%;
  bottom: 5%;
}
