.switch-container input[type="radio"] {
  display: none;
}

.switch-container label {
  color: white;
  z-index: 10;
  cursor: pointer;
}

.switch-container label h6 {
  font-size: 11px;
  font-weight: 200;
  text-transform: uppercase;
  transition: ease 0.3s;
}

.switch-container label h6:hover {
  transform: scale(1.05);
}

.switch-container .black-font {
  color: var(--open) !important;
}

.switch-container.light .black-font {
  color: var(--shoreline-dark) !important;
}

.switch-container {
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  border-radius: 100px;
  background-color: var(--open);
  position: relative;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 30, 0.5);
  user-select: none;
  margin: 12px 0;
}

.switch-container.light {
  background-color: var(--shoreline);
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 100, 0.2);
}

.triple.switch-container {
  width: 101%;
  left: -1%;
}

.double.switch-container {
  width: 80%;
  text-align: center;
}

.switch {
  height: 24px;
  background-color: white;
  border-radius: 100px;
  position: absolute;
  left: 3px;
  top: 3px;
  transition: all 0.3s ease-in-out;
}

.triple .switch {
  width: 33%;
}

.double .switch {
  width: 50%;
}

/*---------------- COMMON ------------------- */
.switch.left-position,
.left-label {
  left: 3px;
  position: absolute;
}

.center-label {
  left: 34%;
  position: absolute;
}

.right-label {
  right: 2px;
  position: absolute;
}

/*---------------- TRIPLE ------------------- */
.triple .switch.center-position {
  left: 35%;
  width: 30%;
}

.triple .center-label {
  left: 33.3%;
}

.triple .switch.right-position {
  left: 66%;
}

/*---------------- DOUBLE ------------------- */
.double .switch.right-position {
  left: 49%;
}

/* Common styles for all labels */
#left + label,
#center + label,
#right + label,
#left:checked + label,
#center:checked + label,
#right:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border-radius: 100px;
}

/* Triple switch widths */
.triple #left + label,
.triple #center + label,
.triple #right + label,
.triple #left:checked + label,
.triple #center:checked + label,
.triple #right:checked + label {
  width: 33%;
}

/* Double switch widths */
.double #left + label,
.double #right + label,
.double #left:checked + label,
.double #right:checked + label {
  width: 50%;
}

/* Hide center label for double switch */
.double #center + label,
.double #center:checked + label {
  display: none;
}
