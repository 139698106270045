.legend-container {
  position: absolute;
  bottom: 35px;
  left: 0px;
  z-index: 2;
  color: white;
  display: grid;
  grid-template-columns: auto 25px;
  gap: 2px;
  max-width: 92vw;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  transition: transform 1s;
}

.legend-content {
  text-align: left;
  display: flex;
  color: black;
}

.legend-item {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  background-color: var(--trench);
  border-radius: 0px 10px 10px 0px;
  border: 2px solid rgb(68, 68, 68);
  margin: 0px;
  max-width: 185px;
  color: white;
  padding: 0 10px;
}

.legend-item.custom-legend-item {
  max-width: 260px;
  width: 260px;
}

.legend-item.wide {
  max-width: 225px;
}
.legend-layer-title {
  font-size: 1.2em;
  padding-top: 8px;
  margin-bottom: -4px;
  @apply font-sans font-bold;
}

.legend-layer-subtitle {
  font-size: 1em;
  @apply font-serif;
}

.open-close-legend-toggle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  margin: 25px 0px;
  border-radius: 50px;
}

.open-close-toggle-text {
  writing-mode: vertical-lr;
  text-orientation: upright;
  font-size: 0.8em;
  cursor: pointer;
}

.legend-layer-toggle {
  display: none;
}

.legend-layer-toggle ~ .legend-layer-toggle {
  display: block;
}

.legend-layer-switch + .legend-layer-toggle {
  display: block;
}

.legend-layer-switch + .legend-layer-toggle ~ .legend-layer-toggle {
  display: none;
}
