.right-panel {
  position: absolute;
  left: 100%;
  width: min(405px, calc(100vw - 65px));
  top: 64px;
  bottom: 0;
  vertical-align: middle;
  display: grid;
  color: rgb(32, 32, 32);
  transition: transform 1s;
  font-weight: normal;
  font-style: normal;
  z-index: 5;
  pointer-events: none;
  font-size: 0.7rem;
  background: white;
  border-radius: 16px 0px 0px 16px;
  border-width: 6px;
  border-color: var(--open);
}

.right-panel > * > * {
  pointer-events: auto;
}

/* Moving the panel off the screen if collapsed */
.right-panel.open {
  transform: translateX(max(-405px, calc(-1 * (100vw - 65px))));
}

.open-sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 102%;
  z-index: 100;
  align-items: center;
}

.open-sidebar {
  display: flex;
}

.open-sidebar h5,
.open-toggle-container svg {
  color: white;
  text-shadow: 0px 0px 8px black;
}

.open-toggle-container {
  width: 40px;
  height: 40px;
  margin: 3px;
  background-color: var(--open);
  border-radius: 50%;
  border: 0.5px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.open-toggle-container.coral {
  border: 1px solid coral;
  animation:
    flash-border-coral-small 2s infinite,
    flash-border-coral-medium 2s 7;
}

.open-toggle-container.cyan {
  border: 1px solid cyan;
}

.open-toggle-container.coral > svg > path {
  fill: coral;
}

.open-toggle-container.coral:hover > svg > path {
  fill: white;
}

.open .open-toggle-container:hover {
  background-color: var(--shoreline);
  border-color: var(--shoreline);
}

.right-panel-content {
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.right-panel-inner-content::-webkit-scrollbar {
  display: none;
}

.top-banner-container {
  position: relative;
  background: var(--open);
}

.coral > * {
  fill: coral;
}

.top-banner-container > .stats-panel-title {
  margin: 10px 10px 6px 10px;
}

.stats-panel-title {
  text-align: right;
  color: #fff;
}

.locations-formatted-text {
  font-size: 1.8em;
  font-weight: 600;
}

.inline-title-text {
  display: inline;
  cursor: pointer;
}

.download-button {
  text-align: right;
}

.locations-list > * {
  cursor: pointer;
}

.stats-panel-locations-tooltip {
  position: fixed;
  z-index: 10;
  background: white;
  opacity: 0.95;
  border-radius: 8px;
  border: 1px solid gray;
  font-size: 20px;
}

.stats-panel-locations-tooltip {
  list-style-type: none;
  text-align: left;
  margin: 10px;
  padding: 10px;
  padding-bottom: 0px;
}

@keyframes flash-border-coral-small {
  0% {
    box-shadow: 0 0 0 1pt rgba(255, 127, 80, 0.5);
    /* background-color: rgba(226, 131, 21, 0.95); */
  }

  50% {
    box-shadow: 0 0 0 2.5pt coral;
    /* background-color: rgba(226, 131, 21, 0.8); */
  }

  100% {
    box-shadow: 0 0 0 1pt rgba(255, 127, 80, 0.5);
    /* background-color: rgba(226, 131, 21, 0.95); */
  }
}

@keyframes flash-border-coral-big {
  0% {
    box-shadow: 0 0 0 1pt rgba(255, 127, 80, 0.8);
  }

  100% {
    box-shadow: 0 0 0 40pt rgba(255, 127, 80, 0.5);
  }
}

@keyframes flash-border-coral-medium {
  0% {
    box-shadow: 0 0 0 1pt rgba(255, 127, 80, 1);
    /* background-color: rgba(226, 131, 21, 0.95); */
  }

  100% {
    box-shadow: 0 0 0 10pt rgba(255, 127, 80, 0.5);
    /* background-color: rgba(226, 131, 21, 0.8); */
  }
}

@keyframes bounce-open {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(-8px);
  }

  100% {
    transform: translateX(0px);
  }
}
