.controls-panel-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 4;
  right: 10px;
  top: 10px;
  color: white;
}

.compass-panel > * {
  cursor: pointer;
}

.compass-container {
  position: absolute;
  right: 20px;
  top: 20px;
}

.controls-panel-title {
  font-size: 10px;
}

.controls-panel {
  display: flex;
}

.controls-panel > * {
  cursor: pointer;
}

.coral > * {
  fill: coral;
}

.controls-icon-container {
  width: 40px;
  height: 40px;
  margin: 3px;
  background-color: var(--open);
  border-radius: 50%;
  border: 3px solid var(--open);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.controls-icon-container.coral {
  border: 1px solid coral;
}

.controls-icon {
  width: 22px;
  height: 22px;
}

.controls-icon.zoom-full {
  padding-top: 10%;
}

.controls-icon-container:hover {
  background-color: var(--shoreline);
  border-color: var(--shoreline);
}

.tooltips-slot {
  background-color: white;
  color: white;
  margin-top: 5px;
  background-color: var(--open);
  border-radius: 20px;
}

.clicked > .controls-icon-container {
  border: 1px solid white;
  background-color: var(--open);
}
